.panel_header {
    display: flex;
    justify-content: space-between;
    background: #043348;
    align-items: center;
    padding: 12px 15px;
}

.panel_header .panel_menu ul {
    display: flex;
    flex-flow: row;
    align-items: center;
    list-style: none;
    margin: 0;

}

.panel_header .panel_menu ul li {
    /* padding: 5px 15px; */
    border: 1px solid #fff;
    border-radius: 7px;
    margin-right: 10px;
}

.panel_header .panel_menu ul li a {
    color: #fff;
    text-decoration: none;
    /* padding: 0 10px; */
    /* width: 75px;
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; */
}
.panel_header .panel_menu ul li:hover {
    background: #fff;
    color: #000;
}
.panel_header .panel_menu ul li:hover a {
    color: #000;
}
.panel_header .panel_menu ul li:hover button {
    color: #000;
}
.panel_header .panel_menu ul li button {
    color: #fff;
    background: transparent;
    border: 0;
    padding: 0;
    width: 75px;
    height: auto;
    padding: 5px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.panel_header .panel_menu ul li button:active {
    outline: none;
    border: none;
}
.panel_header .panel_menu ul li:hover button:active {
    border:0;
}

.search_bar {
    width: 200px;
    height: 36px;
    border: 1px solid #d7cccc;
    padding: 0 0 0 12px;
    border-radius: 5px;
}

.submenu {
    display: none;
    position: absolute;
    background: #f5f5f5;
    left: 0;
    top: 48px;
    bottom: auto;
    height: 355px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: .3s cubic-bezier(.25, .8, .5, 1);
    transition: .3s cubic-bezier(.25, .8, .5, 1)
}

.submenu .search_video_header {
    height: 26px;
    border: 0;
    border-bottom: 1px solid #00000061;
    background: #f5f5f5;
    padding: 10px;
}

.submenu a {
    display: block;
    padding: 16px 10px !important;
    text-align: left;
    font-size: 14px;
    color: #222 !important;
}

.sunmenu_li:hover .submenu {
    color: #fff !important;
    display: block;
    margin-top:-8px;
}
.submenu.submenu_li_1 button {
    background: #13b0c7;
    color: #fff;
    padding: 6px 14px;
    border: 0;      
}
.sunmenu_li {
    position: relative;
}

.submenu a:hover {
    background: #000;
    color: #fff !important;
}

.sunmenu_li img {
    width: 29px;
    filter: invert(1);
    margin-top: -2px;
}

.sunmenu_li {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.sunmenu_li a {
    display: flex !important;
    align-items: flex-start !important;
}

.submenu_li_1 {
    height: auto;
}
.headUserName {
    border: 0 !important;
    display: flex;
    align-items: center;
}
.headUserName button {
    border: 0 !important;
}
.headUserName:hover {
    background: transparent !important;
}
.headUserName img {
    width: 22px;
    margin-right: 2px;
}
.headUserName button {
    color: #fff !important;
}