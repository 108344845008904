.edit_selectbox {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 12px;
}

.hotspot_label input {
    width: 100%;
    border: 1px solid #00000052;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 20px;
}

.hotspot_label {
    display: flex;
    justify-content: center;
    margin: 30px auto 10px;
}

.hotspot_opts h4 {
    margin: 5px 0;
    font-size: 22px;
    list-style: 35px;
    color: #043348;
    font-weight: 500;
}

.hotspot_opts_1 label {
    font-size: 20px;
    margin-left: 10px;
}
.edit_selectbox_div h4 {
    font-size: 20px;
    margin: 10px 0 0 0;
}
.hotspot_opts_1 input {
    width: 22px;
    height: 22px;
}
.hotspot_opts_1 {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    margin: 20px auto 20px;
}
.hotspot_position_1 h4 {
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 20px;
}
.hotspot_edit_check {
    flex-flow: column;
}
.edit_hotspot_ck {
    display: flex;
    align-items: center;
}
/* .hotspot_opts {
    display: flex;
    flex-flow: row;
    justify-content: center;
} */

.hotspot_opts_2 {
    display: flex;
    flex-flow: column;
}

.hotspot_opts_2 label {
    margin: 6px 6px;
}

.hotspot_position h2 {
    margin: 5px 0;
    font-size: 18px;
    color: #043348;
    font-weight: 500;
}

.hotspot_position_1 select {
    width: 100% !important;
    height: 50px !important;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #00000052;
}

.hotspot_position {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.hotspot_position_2 {
    margin-left: 10px;
}

.hotspot_position_2 select {
    width: 220px;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #00000052;
}

.hotspot_position_1 {
    margin-right: 10px;
    width: 100%;
}

.buttons_save .btn_cancel {
    background: transparent;
    border: 0;
    font-size: 18px;
    margin-right: 22px;
    color: #555;
}

.buttons_save {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-top: 22px;
}

.btn_save {
    background: #043348;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    color: #fff;
    width: 140px;
    height: 55px;
    font-size: 20px
}