.pause_selectbox {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 12px;
}

.hotspot_label input {
    width: auto;
    border: 1px solid #00000052;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 20px;
}
.action_link span {
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
}
.action label{
    display: block;
    margin: 20px 0 10px 0;
    font-size: 20px;   
}

.edit_checkbox span{
    font-size: 17px;
    margin-left: 9px;    
}
.containerTxt {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    /* width: auro;
    max-width: var(--ifm-container-width);
    padding-left: 1rem;
    padding-left: var(--ifm-spacing-horizontal);
    padding-right: 1rem;
    padding-right: var(--ifm-spacing-horizontal); */
    width: 400px;
    height: 450px;
    /* border: 1px solid black; */
}

.hotspot_opts h4 {
    margin: 5px 0;
    font-size: 18px;
    color: #043348;
    font-weight: 500;
}

.hotspot_opts_1 label {
    margin: 8px 0;
}

.hotspot_opts_1 {
    display: flex;
    flex-flow: column;
}

.hotspot_opts {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.hotspot_opts_2 {
    display: flex;
    flex-flow: column;
}

.hotspot_opts_2 label {
    margin: 6px 6px;
}

.hotspot_position h2 {
    margin: 5px 0;
    font-size: 18px;
    color: #043348;
    font-weight: 500;
}

.hotspot_position_1 select {
    width: 220px;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #00000052;
}

.hotspot_position {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.hotspot_position_2 {
    margin-left: 10px;
}

.hotspot_position_2 select {
    width: 220px;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #00000052;
}

.hotspot_position_1 {
    margin-right: 10px;
}

.buttons_save .btn_cancel {
    background: transparent;
    border: 0;
    font-size: 18px;
    /* margin-right: 22px; */
    margin: 0 0px 0 10px;
    color: #555;
}

.buttons_save {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-top: 30px;
}

.btn_save {
    background: #043348;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    color: #fff;
    width: 140px;
    height: 55px;
    font-size: 20px
}
/* 
.action_values {
    margin-left: 20px;
}
*/
.action_link {
    margin-top: 20px;
    margin-bottom: 20px;
}

.action_vid  {
    margin-top: 20px;
}

.action_vid  select {
    margin-left: 20px;
}

.action_image {
    margin-top: 20px;
}

.action_image select {
    margin-left: 20px;
}

.action_message {
    margin-top: 20px;
}