.panel_side_icons ul {
    list-style: none;
    display: flex;
    flex-flow: column;
    height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
    margin: 20px 10px 0 0px;

}
.panel_side_icons ul li img {
    width: 30px;
}
.panel_side_icons ul li {
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
}
.panel_side_icons ul li span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.my-video-dimensions{
    margin-top: 25px;
}
.panel_body_fl {
    margin-bottom: 50px;
    display: flex;
    flex-flow: row;
    margin-top: 10px;
    padding: 0 14px;
}
.panel_side_icons ul li:hover span {
    color: #2187a3;
}
.form_opt_images img {
    width: 30px;
}
.form_opt_images span {
    display: block;
}
.form_options {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 20px 10px;
    margin: 15px 40px;
    border: 1px solid #0000000d;
}
.form_opt_edit img {
    width: 20px;
    margin: 0 6px;
    cursor: pointer;
}
.form_opt_images_txt {
    display: flex;
    flex-flow: column;
    /* margin-right: 40px; */
    justify-content: flex-start;
        width: 300px;
}
.form_opt_images_txt p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}
.form_opt_images_txt span {
    font-size: 14px;
    margin: 5px 0;
}
.form_opt_images_txt button {
    background: linear-gradient(3deg,#11919c,#000851);
    border: 0;
    padding: 4px 9px;
    cursor: pointer;
    margin-top: 8px;
    border-radius: 5px;
    width: max-content;

}
.form_opt_images_txt button a {
 color: #fff;   
 text-decoration: none;
 font-size: 13px;
}
.panelDownloadImg{
 color: #fff;
 text-decoration: none;
 font-size: 13px;
}
.panel_form {
    width: 100%;
    height: 550px;
    overflow: auto;
}
.panel_form h2{
    text-align: center;
    font-size: 20px;
    margin-bottom: 12px
}
.form_options:hover {
    background: #00000005;
}
.form_options:hover .form_opt_images_txt button {
    background: linear-gradient(3deg,#000851,#11919c);
    color: #fff;
}
.panel_side_icons ul::-webkit-scrollbar {
    width: 7px;
}
.panel_side_icons ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #88888870;
    border-radius: 10px;
}
.panel_side_icons ul::-webkit-scrollbar-thumb {
    background: linear-gradient(3deg,#11919c,#000851);
    border-radius: 10px;
}
.panel_side_icons ul::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(3deg,#000851,#11919c);
}
.panel_form::-webkit-scrollbar {
    width: 8px;
}
.panel_form::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #88888870;
    border-radius: 10px;
}
.panel_form::-webkit-scrollbar-thumb {
    background: linear-gradient(3deg,#11919c,#000851);
    border-radius: 10px;
}
.panel_form::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(3deg,#000851,#11919c);
}
.search_bar {
    width: 200px;
    height: 36px;
    border: 1px solid #d7cccc;
    padding: 0 0 0 12px;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
}
/* modal_css */
.modal{
    --bs-modal-width: 45% !important;
}
.modal-header .btn-close {
    position: absolute;
    top: 4%;
    left: auto;
    right: 20px;
}
/* .config_poster_inp {
    width: 450px;
    border-radius: 0px !important;
    box-shadow: 0 1px 2px 0 rgb(29 37 45 / 10%) !important;
    border: solid 1px rgba(127, 125, 142, 0.1) !important;
    font-size: 15px !important;
    height: 50px;
    padding: 0px 17px;
    margin-bottom: 25px;
} */